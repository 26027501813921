import Toast from "../Toast/Toast";
import "./ToastList.css";
import React, { useRef, useEffect } from 'react';

export default function ToastList({ data, position, removeToast }) {
    const listRef = useRef(null);

    const handleScrolling = (el) => {
        const isTopPosition = ["top-left", "top-right"].includes(position);
        if (isTopPosition) {
          el?.scrollTo(0, el.scrollHeight);
        } else {
          el?.scrollTo(0, 0);
        }
      };

    const sortedData = position.includes("bottom")
    ? [...data].reverse()
    : [...data];
    
    useEffect(() => {
    handleScrolling(listRef.current);
    }, [position, data]);

    return (
        sortedData.length > 0 && (
          <div
            className={`toast-list toast-list--${position}`}
            aria-live="assertive"
          >
            {sortedData.map((toast) => (
              <Toast
                key={toast.id}
                message={toast.message}
                type={toast.type}
                onClose={() => removeToast(toast.id)}
              />
            ))}
          </div>
        )
      );
};
