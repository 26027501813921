import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../index.css";

import { isSignInWithEmailLink, signInWithEmailLink, getAdditionalUserInfo } from 'firebase/auth';
import { auth } from '../firebase/firebase';

import Container from "@mui/material/Container";
import Skeleton from "./Skeleton/Skeleton";

export default function LoginFinish({handleShowToast}) {
    const navigate = useNavigate();
    const location = useLocation();

    let REDIRECT_PAGE = '/';
    let searchParams = new URLSearchParams(location.search);
    let type = searchParams.get("type");
    if (type === "info") {
        REDIRECT_PAGE = "/sellTickets/info";
    } else if (type === "buy") {
        REDIRECT_PAGE = `/event/${searchParams.get("event")}#${searchParams.get("listing")}`;
    }

    if (isSignInWithEmailLink(auth, window.location.href)) {
        // Additional state parameters can also be passed via URL.
        // This can be used to continue the user's intended action before triggering
        // the sign-in operation.
        // Get the email if available. This should be available if the user completes
        // the flow on the same device where they started it.
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          // User opened the link on a different device. To prevent session fixation
          // attacks, ask the user to provide the associated email again. For example:
          email = window.prompt('Please provide your email for confirmation');
        }
        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, email, window.location.href)
          .then((result) => {
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            if (getAdditionalUserInfo(result)?.isNewUser) {
                handleShowToast("Thanks for signing up!", "success");
            } else {
                handleShowToast("Welcome Back!", "success");
            }
            navigate(REDIRECT_PAGE);
            // You can access the new user by importing getAdditionalUserInfo
            // and calling it with result:
            // getAdditionalUserInfo(result)
            // You can access the user's profile via:
            // getAdditionalUserInfo(result)?.profile
            // You can check if the user is new or existing:
          })
          .catch((error) => {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
            if (error.code === "auth/invalid-action-code") {
              handleShowToast("This login link has been used before or has expired", "failure");
              handleShowToast("Please try signing in again with a new link", "failure");
              navigate("/login");
            } else {
              handleShowToast("Something went wrong please try signing in again");
              navigate("/login");
            }
          });
      } else {
        handleShowToast("Failed to sign you in, please try again", "failure");
        navigate("/login");
      }

      return (
        <Container maxWidth='xs' sx={{ minHeight: '90vh', maxWidth: '408px', minWidth: '346px'}}>
            <Skeleton Type="signin" />
        </Container>
      )
}