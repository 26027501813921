import React from 'react';
import { Typography, Container, Box, Link } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Privacy Policy for Entick
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Effective Date: 24th of September 2024</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        At Entick, accessible from <Link href="/">www.entick.co.uk</Link>, we are committed to protecting your personal data and your right to privacy. This Privacy Policy explains what information we collect, how we use it, and the choices you have in relation to your information.
      </Typography>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          1. Who We Are
        </Typography>
        <Typography variant="body1" gutterBottom>
          Entick is a ticket marketplace that enables users to buy and sell event tickets. If you have any questions or concerns about this policy or our practices, you can contact us at:
          <br />
          <strong>Email</strong>: <Link href="mailto:directors@entick.co.uk">directors@entick.co.uk</Link>
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          2. What Data We Collect
        </Typography>
        <Typography variant="body1" gutterBottom>
          We collect and process different types of personal information depending on how you use our services.
        </Typography>
        <Typography variant="body1" component="ul" gutterBottom>
          <li><strong>Email Address</strong>: We collect your email when you sign up for an account or make a purchase.</li>
          <li><strong>Additional Data for Sellers</strong>: Our payment processor (Stripe) collects your name, phone number, bank account information, and date of birth when you become a seller.</li>
        </Typography>
        <Typography variant="body1" gutterBottom>
          We do not collect personal data through cookies. However, we use Simple Analytics to gather anonymized data about website traffic and user behavior.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          3. How We Use Your Data
        </Typography>
        <Typography variant="body1" gutterBottom>
          We use your data for the following purposes:
          <ul>
            <li>Account creation and access to our platform</li>
            <li>Payment processing through Stripe</li>
            <li>Emailing purchased tickets via SendGrid</li>
            <li>Website analytics to improve the user experience</li>
          </ul>
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          4. Third-Party Services
        </Typography>
        <Typography variant="body1" gutterBottom>
          We share your data with third-party services that help us operate our marketplace:
          <ul>
            <li><strong>SendGrid</strong>: For sending email notifications and tickets</li>
            <li><strong>Stripe</strong>: For payment processing</li>
            <li><strong>Algolia</strong>: For search functionality</li>
            <li><strong>Firebase</strong>: For hosting and data storage</li>
          </ul>
          These third parties may only access your data to perform their designated tasks and are obligated to protect your data.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          5. Cookies and Tracking
        </Typography>
        <Typography variant="body1" gutterBottom>
          We do not use cookies for tracking or advertising purposes. Any cookies on our site are essential for the platform’s functionality.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          6. How We Protect Your Data
        </Typography>
        <Typography variant="body1" gutterBottom>
          We store your data on Firebase, a secure cloud provider, with encrypted storage. All communications with our servers occur over HTTPS.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          7. Data Retention
        </Typography>
        <Typography variant="body1" gutterBottom>
          We retain your data for as long as your account is active or as needed to provide services. If your account is inactive for over one year, we will delete your data.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          8. Your Rights
        </Typography>
        <Typography variant="body1" gutterBottom>
          You have the right to:
          <ul>
            <li>Access your data</li>
            <li>Correct inaccurate information</li>
            <li>Delete your personal data</li>
          </ul>
          To exercise these rights, please email us at <Link href="mailto:directors@entick.co.uk">directors@entick.co.uk</Link>.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          9. Children's Privacy
        </Typography>
        <Typography variant="body1" gutterBottom>
          We do not knowingly collect any personal information from children under the age of 16. If we discover we have inadvertently collected such data, we will delete it immediately.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          10. Changes to This Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may update this Privacy Policy from time to time. You will be notified via email or a notice on our website when changes occur. Please review this policy periodically.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="body1" gutterBottom>
          For more information, feel free to contact us at: <Link href="mailto:directors@entick.co.uk">directors@entick.co.uk</Link>.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
