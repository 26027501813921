import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';
import PaymentStatus from "./PaymentStatus";
import ListingFetchModel from "../models/getData/ListingFetchModel";
import { useAuth } from "../firebase/auth";
import Skeleton from "./Skeleton/Skeleton";
import UserFetchModel from "../models/getData/UserFetchModel";
import Grid from "@mui/material/Grid";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { config } from '../index';

export default function Checkout({ handleShowToast }) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [total, setTotal] = useState(5);
    const [allTickets, setAllTickets] = useState([]);
    const { authUser, isLoading } = useAuth();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({
        uid: null,
        email: null
    });

    let urlPaymentIntent = searchParams.get("payment_intent");
    let urlClientSecret = searchParams.get("payment_intent_client_secret");
    // Stripe setup
    const stripePromise = loadStripe(config.stripe_promise);

    const getTotal = () => {
        setLoading(true);
        ListingFetchModel.fetchCartItems(authUser).then((response) => {
            if (response.success) {
                let tickets = [];
                let grandTotal = (Math.round((response.total * 100) + (response.fee * 100)))/100;
                setTotal(grandTotal.toFixed(2));
                for (let i = 0; i < response.data.length; i++) {
                    for (let j = 0; j < response.data[i].tickets.length; j++) {
                        tickets.push(response.data[i].tickets[j]);
                    }
                }
                if (tickets.length === 0) {
                    handleShowToast("Couldn't find any tickets to buy. Your cart may have expired", "failure");
                    navigate("/cart");
                }
                setAllTickets(tickets);
                setLoading(false);
            }
        });
    };
    
    const getUserInfo = () => {
        setLoading(true);
        UserFetchModel.fetchUser(authUser.uid).then((response) => {
            if (response.success) {
                setUser({
                    uid: authUser.uid,
                    email: response.data.email
                });
                setLoading(false);
            }
        });
    };

    const options = {
        clientSecret: urlClientSecret,
        appearance: {
            theme: "flat",
        },
    };

    useEffect(() => {
        if (authUser) {
            if (total === 0 || allTickets.length === 0) {
                getTotal();
            }
            if (authUser.email === null) {
                getUserInfo();
            }
        }
    }, [authUser, total, allTickets]);

    if (urlPaymentIntent) {
        return (
            <Elements stripe={stripePromise} options={options.clientSecret}>
                {allTickets.length > 0 ? (
                    <PaymentStatus 
                        user={authUser.email === null ? user : authUser} 
                        item={options.clientSecret} 
                        handleShowToast={handleShowToast} 
                        tickets={allTickets} 
                    />
                ) : (
                    <Skeleton Type="circular" />
                )}
            </Elements>
        );
    } else if (urlClientSecret !== "") {
        return (
            <Container maxWidth="md" sx={{ minHeight: "90vh", paddingTop: '2rem', paddingBottom: '2rem' }}>
                {loading ? (
                    <Skeleton Type="circular" />
                ) : (
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 3 }}>
                                Payment
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 3, color: '#555' }}>
                                The tickets can be downloaded under the My Tickets section in your profile.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={7} md={8}>
                            <Card sx={{ borderRadius: '16px', boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)' }}>
                                <CardContent>
                                    <Elements stripe={stripePromise} options={options}>
                                        <CheckoutForm />
                                    </Elements>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={5} md={4}>
                            <Card sx={{ borderRadius: '16px', boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)', padding: '24px' }}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px' }}>
                                    Order Total
                                </Typography>
                                <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '8px' }}>
                                    £{total}
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#666', marginBottom: '16px' }}>
                                    the total includes the Entick fees
                                </Typography>
                                {/* Add a question mark with a tooltip or popover to show a breakdown of the fees */}
                            </Card>
                        </Grid>
                    </Grid>
                )}
            </Container>
        );
    } else {
        return <Skeleton Type="circular" />;
    }
}
