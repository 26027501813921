import { db } from "../../firebase/firebase";
import { doc, setDoc, serverTimestamp, addDoc, collection } from "firebase/firestore";

export default class WaitListPostModel {
    static async AddToWaitList(email) {
        try {
            const firstHalf = email.split('@')[0];
            const docRef = await addDoc(collection(db, "waitlist"), {
                email: email,
                createdAt: serverTimestamp(),
            });
            return {
                success: true,
                data: docRef
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }
}