import { getDoc, doc, collection, updateDoc, where, orderBy, query, getDocs } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import {config} from "../../index"
import { getFunctions, httpsCallable } from "firebase/functions";

export default class Transaction {

    static async getPaymentIntentClientSecret() {
        try {
            const funct = getFunctions();
            const response = await httpsCallable(funct, 'createPaymentIntent')();
            return {
                success: true,
                data: response.data
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async buyCart(user) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            }
            
            const funct = getFunctions();
            const response = await httpsCallable(funct, 'buyUserCart')({user: user.uid, email: user.email});
            return {
                success: response.data.success,
                error: response.data.message
            }

        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    
    static async getPayouts(user) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            }
            let payoutsQuery = query(collection(db, "payouts"), where("to", "==", user.uid));
            const payoutsQuerySnapshot = await getDocs(payoutsQuery);
            let data = [];
            payoutsQuerySnapshot.forEach((doc) => {
                const { amount, to, createdAt, status } = doc.data();
                data.push({
                    id: doc.id,
                    amount: amount,
                    to: to,
                    createdAt: new Date(createdAt.seconds * 1000),
                    status: status
                });
            });
            return {
                success: true,
                data: data
            };
        } catch (error) {
            return {
                success: false,
                error: "Error getting payouts"
            }
        }
    }
            

    static async connectedAccountLink(email) {
        if (email) {
            try {
                const funct = getFunctions();
                const response = await httpsCallable(funct, 'createConnectedAccount')({email: email, return_url: config.return_url, refresh_url: config.return_url}); 
                return {
                    success: true,
                    account: response.data.id,
                    link: await response.data.url
                }
            } catch (error) {
                return {
                    success: false,
                    error: error
                }
            }
        } else {
            return {
                success: false,
                error: "wrong email"
            }
        }
    }

    static async connectedAccountOnboardingLink(accountId) {
        try {

            if (accountId === "" || accountId === null) {
                return {
                    success: false,
                    error: "Invalid account, can't fetch the onboarding link"
                }
            }
            const funct = getFunctions();
            const response = httpsCallable(funct, 'fetchAccountOnboardingLink')({id: accountId, refresh_url: config.return_url, return_url: config.return_url});

            return {
                success: true,
                link: (await response).data.url
            }
        } catch (error) {
            return {
                success: false,
                error: "Error fetching onboarding link"
            }
        }
    }

    static async fetchConnectedAccountFromStripe(accountId) {
        try {
            const funct = getFunctions();
            const response = httpsCallable(funct, 'fetchConnectedAccount')({id: accountId});
            try {
                const data = (await response).data;
                if (data.details_submitted || data.payouts_enabled) {
                    return {
                        success: true
                    }
                } else {
                    return {
                        success: false,
                        error: "details were not submitted or not enough, for payouts to be enabled"
                    }
                }
            } catch (error) {
                return {
                    success: false,
                    error: error
                }
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }

    static async getConnectedAccount(accountId) {
        try {
            const userDoc = await getDoc(doc(db, "user", accountId.uid));
            const data = userDoc.data();
            if (data) {
                if (data.connectedAccount.details_submitted === true) {
                    return {
                        success: true,
                        account: {
                            id: data.connectedAccount.id,
                            chargesEnabled: data.connectedAccount.charges_enabled,
                            detailsSubmitted: data.connectedAccount.details_submitted,
                            accountDetails: {
                                bankName: data.connectedAccount.external_accounts.data[0].bank_name,
                                last4: data.connectedAccount.external_accounts.data[0].last4,
                                routingNumber: data.connectedAccount.external_accounts.data[0].routing_number
                            }
                        }
                    };
                } else {
                    return {
                        success: false,
                        account: {
                            id: accountId,
                            chargesEnabled: false
                        }
                    };
                }
            } else {
                return {
                    success: false,
                    error: "There was an error when fetching the bank details"
                };
            }
        } catch (error) {
            return {
                success: false,
                error: "There was an error when fetching the bank details"
            };
        };
    }

    static async verifyConnectedAccount(user, accountId) {
        try {
            if (!user) {
                return {
                    success: false,
                    error: "User not logged in"
                }
            }

            await updateDoc(doc(db, "user", user.uid), {
                connectedAccountId: accountId,
            });

            return {
                success: true,
            };
        } catch (error) {
            return {
                success: false,
                error: "Error verifying account"
            }
        }
    }
    
}