import React from 'react';
import { Typography, Container, Box, Link } from '@mui/material';

const TermsOfService = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Terms of Service for Entick
      </Typography>
      <Typography variant="body1" gutterBottom>
        <strong>Effective Date: 24th of September 2024</strong>
      </Typography>

      <Typography variant="body1" gutterBottom>
        Welcome to Entick, a platform for buying and selling second-hand tickets for night club events. By accessing or using our services, you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use our platform.
      </Typography>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          1. General Information
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Marketplace Name</strong>: Entick<br />
          <strong>Contact Email</strong>: For any inquiries, please contact us at <Link href="mailto:directors@entick.co.uk">directors@entick.co.uk</Link>.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          2. Use of the Service
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Primary Service</strong>: Entick enables users to buy and sell second-hand tickets for night club events.<br />
          <strong>Account Requirements</strong>: You must be at least 16 years old to create an account. Sellers may need to verify their identity after surpassing a certain sales threshold, as determined by our payment processor, Stripe.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          3. Payment and Fees
        </Typography>
        <Typography variant="body1" gutterBottom>
          Entick charges a <strong>5% service fee</strong> to both the seller and the buyer for each transaction. These fees cover both Entick's service and the payment processing fees via Stripe.<br />
          <strong>No Refunds</strong>: Entick does not offer refunds for transactions completed through the platform.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          4. Prohibited Activities
        </Typography>
        <Typography variant="body1" gutterBottom>
          You agree not to engage in any of the following prohibited activities:
          <ul>
            <li>Providing false or misleading information.</li>
            <li>Selling the same ticket more than once or attempting to resell a ticket after it has already been sold.</li>
            <li>Selling tickets or items that are not accepted by the event organizers.</li>
          </ul>
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          5. User-Generated Content (Listings)
        </Typography>
        <Typography variant="body1" gutterBottom>
          Users are permitted to post listings for tickets they wish to sell. However, tickets must be unique, and each ticket must correspond to the event under which it is listed.<br />
          If a seller receives consecutive negative reviews, Entick reserves the right to remove their listings and block their account from the platform.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          6. Termination of Services
        </Typography>
        <Typography variant="body1" gutterBottom>
          Entick may terminate your account and access to the service without prior notice if you:
          <ul>
            <li>Commit fraud or engage in any activity that violates these Terms.</li>
            <li>Provide false information or engage in prohibited activities.</li>
          </ul>
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          7. Limitation of Liability
        </Typography>
        <Typography variant="body1" gutterBottom>
          <strong>Disputes Between Users</strong>: Entick is not responsible for disputes between buyers and sellers. Any disputes must be resolved directly between the involved parties.<br />
          <strong>No Liability for Transactions</strong>: Entick is a marketplace platform and is not liable for the condition, quality, or delivery of tickets sold through the platform.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          8. Governing Law
        </Typography>
        <Typography variant="body1" gutterBottom>
          These Terms shall be governed by and construed in accordance with the laws of the <strong>United Kingdom</strong>.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          9. Changes to the Terms
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may update these Terms from time to time. If changes are made, we will notify users via email or a notice on the platform. Your continued use of the platform following the update constitutes your acceptance of the new Terms.
        </Typography>
      </Box>

      <Box mt={4}>
        <Typography variant="body1" gutterBottom>
          For any questions regarding these Terms, please contact us at <Link href="mailto:directors@entick.co.uk">directors@entick.co.uk</Link>.
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsOfService;
