import React, { useState } from 'react';
import { Box, Button, Typography, Link } from '@mui/material';
import styled from '@emotion/styled';

const CookieBanner = styled(Box)({
  position: 'fixed',
  bottom: 0,
  width: 'calc(100% - 24px)',
  backgroundColor: '#f9f9f9',
  borderTop: '1px solid #ebebeb',
  boxShadow: '0px -2px 8px rgba(0, 0, 0, 0.1)',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1000,
});

const ConsentButton = styled(Button)({
  backgroundColor: '#8146F6',
  color: '#fff',
  fontWeight: 'bold',
  padding: '0.5rem 1rem',
  borderRadius: '20px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#6b3acf',
  },
});

export default function CookieConsent() {
  const [showBanner, setShowBanner] = useState(true);

  const handleAccept = () => {
    // Set consent in local storage or cookies
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
  };

  if (!showBanner || localStorage.getItem('cookieConsent') === 'true') {
    return null;
  }

  return (
    <CookieBanner>
      <Typography variant="body2" sx={{ color: '#333' }}>
        We use cookies to ensure you get the best experience on our website. For more information, read our{' '}
        <Link href="/privacy" sx={{ color: '#8146F6', textDecoration: 'underline' }}>
          Privacy Policy
        </Link>.
      </Typography>
      <ConsentButton onClick={handleAccept}>Accept Essential Cookies</ConsentButton>
    </CookieBanner>
  );
}
