import React, { useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../firebase/auth";
import Authenticator from "../models/Authenticator";
import Skeleton from "./Skeleton/Skeleton";
import Transaction from "../models/postData/Transaction";


export default function SellerCheck({handleShowToast}) {
    const { authUser, isLoading } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({
        username: "",
        termsAccepted: false,
        email: "",
        avgRating: 0,
        numReviews: 0,
        payouts_enabled: false,
        connectedAccountId: "",
        stripeCustomerId: ""
    });

    const getUserData = async () => {
        Authenticator.getUserData(authUser).then((response) => {
            if (response.success) {
                response.data.email = authUser.email;
                setUserData(response.data);
                handleShowToast("Be patient, this might take a minute", "warning");
                Transaction.fetchConnectedAccountFromStripe(response.data.connectedAccountId).then((res) => {
                    if (response.data.payouts_enabled || res.success) {
                        handleShowToast("You are now a verified seller!", "success");
                        setLoading(false);
                        navigate("/sellingSetup#1");
                        window.scrollTo(0, 0);
                    } else {
                        handleShowToast("Not enough information to set up a seller account, please try again", "failure");
                        setLoading(false);
                        navigate("/sellingSetup#0");
                        window.scrollTo(0, 0);
                    }
                })
                setLoading(false);
            } else {
                handleShowToast("Error getting user data, please try again", "failure");
                setLoading(false);
                navigate("/sellingSetup#0");
            }
        })
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        if (!isLoading && authUser) {
            getUserData();
        }
    }, [isLoading, authUser]);

    return (
        <section className="main">
            <div className="event-page">
                <Skeleton Type="logo"/>
                <Skeleton Type="circular"/>
            </div>
        </section>
    )
}