// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBQsN4QM9XlYtqPuteSht4sF0-bVeDyvNA",
  authDomain: "entick.co.uk",
  projectId: "entick-211c9",
  storageBucket: "entick-211c9.appspot.com",
  messagingSenderId: "838861652691",
  appId: "1:838861652691:web:a6f30dd4e4635769ae4a26",
  measurementId: "G-8C5PSCELNN"
};

// const devConfig = {
//   apiKey: "AIzaSyCPzzKjKLvTnYdcbU36Pnh1xb0Qfc3vviI",
//   authDomain: "entick-dev-15d0d.firebaseapp.com",
//   projectId: "entick-dev-15d0d",
//   storageBucket: "entick-dev-15d0d.appspot.com",
//   messagingSenderId: "806641883676",
//   appId: "1:806641883676:web:02e07d53ebfd4fbf431dd9",
//   measurementId: "G-3FK27K5EJ3"
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);