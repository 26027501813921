import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)({
  textTransform: 'none',
  fontSize: '16px',
  fontWeight: 'bold',
  borderRadius: '20px',
  padding: '0.6rem 1.2rem',
  marginTop: '1.5rem',
});

const NotFound = () => {
  const navigate = useNavigate();

  const handleGoDiscover = () => {
    navigate('/discover');
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', paddingTop: '4rem' }}>
      <SentimentDissatisfiedIcon sx={{ fontSize: '5rem', color: '#8146F6' }} />
      <Typography variant="h2" sx={{ fontWeight: 'bold', color: '#8146F6', marginBottom: '1rem' }}>
        Oops! Page Not Found
      </Typography>
      <Typography variant="body1" sx={{ color: '#666', fontSize: '1.2rem', marginBottom: '2rem' }}>
        It seems you've stumbled into the void... But don't worry, we've got plenty to explore!
      </Typography>
      <Box>
        <StyledButton variant="outlined" onClick={handleGoDiscover} sx={{ color: '#8146F6', borderColor: '#8146F6', marginLeft: '1rem' }}>
          Discover Events
        </StyledButton>
      </Box>
    </Container>
  );
};

export default NotFound;
