import { getDoc, doc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

function userToObject(doc) {
    return {
        key: doc.id,
        termsAccepted: doc.data().termsAccepted,
        connectedAccountId: doc.data().connectedAccountId,
        stripeCustomerId: doc.data().stripeCustomerId,
        email: doc.data().email,
        avgRating: doc.data().avgRating,
        numReviews: doc.data().numReviews,
        username: doc.data().username,
    }
}

export default class UserFetchModel {
    static async fetchUser(id) {
        try {
            const docRef = doc(db, "user", id);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
                try {
                    let user = userToObject(docSnapshot);
                    return {
                        success: true,
                        data: user
                    }
                } catch (error) {
                    return {
                        success: false,
                        error: error
                    }
                }
            } else {
                return {
                    success: false,
                    error: "No such user!"
                }
            }
        } catch (error) {
            return {
                success: false,
                error: error
            }
        }
    }
}

export { userToObject };
