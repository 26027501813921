import React from "react";
import "./Icons.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, 
  faXmark, 
  faCircleExclamation, 
  faTriangleExclamation, 
  faTrashCan,
  faCaretDown, 
  faCaretUp, 
  faSquareUpRight, 
  faPenToSquare, 
  faUser,
  faTicket,
  faList,
  faCreditCard,
  faRightFromBracket,
  faCalendarDays,
  faLocationDot,
  faGaugeHigh,
  faShield,
  faCouch,
  faHandshakeSimple,
  faPersonRunning, 
  faHands,
  faCartShopping, 
  faCircleHalfStroke,
  faPlus,
  faMinus
 } from '@fortawesome/free-solid-svg-icons'

const CloseIcon = () => (
    <FontAwesomeIcon icon={faXmark} />
  );
  
  const SuccessIcon = () => (
    <FontAwesomeIcon icon={faCircleCheck} />
  );
  
  const FailureIcon = () => (
    <FontAwesomeIcon icon={faCircleExclamation} />
  );
  
  const WarningIcon = () => (
    <FontAwesomeIcon icon={faTriangleExclamation} />
  );

  const DeleteIcon = () => (
    <FontAwesomeIcon icon={faTrashCan} />
  );

  const CaretDown = () => (
    <FontAwesomeIcon icon={faCaretDown} />
  );

  const CaretUp = () => (
    <FontAwesomeIcon icon={faCaretUp} />
  );

  const OpenIcon = () => (
    <FontAwesomeIcon icon={faSquareUpRight} />
  );

  const EditIcon = () => (
    <FontAwesomeIcon icon={faPenToSquare} />
  );
  
  const UserIcon = () => (
    <FontAwesomeIcon icon={faUser} />
  );

  const TicketIcon = () => (
    <FontAwesomeIcon icon={faTicket} />
  );

  const ListIcon = () => (
    <FontAwesomeIcon icon={faList} />
  );

  const CreditCardIcon = () => (
    <FontAwesomeIcon icon={faCreditCard} />
  );

  const RightFromBracket = () => (
    <FontAwesomeIcon icon={faRightFromBracket} />
  );

  const CalendarIcon = () => (
    <FontAwesomeIcon icon={faCalendarDays} />
  );

  const LocationDot = () => (
    <FontAwesomeIcon icon={faLocationDot} />
  );

  const FastIcon = () => (
    <FontAwesomeIcon icon={faGaugeHigh} />
  );

  const ShieldIcon = () => (
    <FontAwesomeIcon icon={faShield} />
  );

  const HandsIcon = () => (
    <FontAwesomeIcon icon={faHands} />
  );

  const HandshakeIcon = () => (
    <FontAwesomeIcon icon={faHandshakeSimple} />
  );

  const RunningManIcon = () => (
  <FontAwesomeIcon icon={faPersonRunning} />
  );

  const CartIcon = () => (
    <FontAwesomeIcon icon={faCartShopping} />
  );

  const HalfCircleIcon = () => (
    <FontAwesomeIcon icon={faCircleHalfStroke} />
  );

  const PlusIcon = () => (
    <FontAwesomeIcon icon={faPlus} />
  );

  const MinusIcon = () => (
    <FontAwesomeIcon icon={faMinus} />
  );

  export { CloseIcon, 
    SuccessIcon, 
    FailureIcon, 
    WarningIcon, 
    DeleteIcon, 
    CaretDown, 
    CaretUp, 
    OpenIcon, 
    EditIcon, 
    UserIcon,
    TicketIcon,
    ListIcon,
    CreditCardIcon,
    RightFromBracket,
    CalendarIcon,
    LocationDot,
    FastIcon,
    ShieldIcon,
    HandsIcon,
    HandshakeIcon,
    RunningManIcon,
    CartIcon,
    HalfCircleIcon,
    PlusIcon,
    MinusIcon };