import React, {useState, useEffect} from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import {useSearchParams, useNavigate} from 'react-router-dom';
import Transaction from '../models/postData/Transaction';
import { useAuth } from "../firebase/auth";
import Skeleton from './Skeleton/Skeleton';

export default function PaymentStatus(props) {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const { authUser, isLoading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!authUser) {
        return;
    }

    // Retrieve the PaymentIntent
    stripe
      .retrievePaymentIntent(props.item)
      .then(({paymentIntent}) => {
        // Inspect the PaymentIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent.status) {
          case 'succeeded':
            Transaction.buyCart(props.user).then((response) => {
                if (response.success) {
                  setMessage('Success! Payment received.');
                  props.handleShowToast("Tickets bought successfully and payment received.", "success");
                  navigate("/profile/mytickets");
                  window.scrollTo(0, 0);
                } else {
                    props.handleShowToast("Something went wrong.", "failure");
                }
            })
            break;

          case 'processing':
            setMessage("Payment processing. Please stay on this page.");
            props.handleShowToast("Payment processing. Please stay on this page.", "warning");
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage('Payment failed. Please try another payment method.');
            props.handleShowToast("Payment failed. Please try another payment method.", "failure");
            navigate("/cart");
            break;

          default:
            setMessage('Something went wrong.');
            props.handleShowToast("Something went wrong.", "failure");
            navigate("/cart");
            break;
        }
      });
  }, [stripe, authUser]);


  return (
    <section className="main">
        <div className="event-page">
            <div className="login-container">
                <h1 className="page-titles">Payment Status</h1>
                {/* Add a image/animation of a tick or a crosss or a loading sign */}
                {message ? <p className="page-description" id="low-margin">{message}</p> : <Skeleton Type="circular"/>}
            </div>
        </div>
    </section>
  )
};