import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  Link,
  IconButton,
  useTheme,
} from '@mui/material';
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
} from '@mui/icons-material';
import MusicNoteIcon from '@mui/icons-material/MusicNote'; // Placeholder for TikTok icon
import WaitListPostModel from '../models/postData/WaitListPostModel';

export default function Footer({handleShowToast}) {
  const navigate = useNavigate();
  const theme = useTheme();

  const navigateTo = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  const emilSubmitHandler = async (form) => {
    form.preventDefault();
    const data = new FormData(form.target);
    const email = data.get('email');
    if (validateEmail(email)) {
      const res = await WaitListPostModel.AddToWaitList(email);
      if (res.success) {
        handleShowToast("Congratulations! We will let you know when our app is out :)", "success")
      } else {
        handleShowToast("An error occurred when trying to add to the waitlist", "failure")
      }
    } else {
      handleShowToast("Please enter a valid email address", "failure")
    }
  }

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#f5f5f5',
        color: theme.palette.text.primary,
        padding: theme.spacing(6, 2),
        mt: 8,
      }}
    >
      <Grid container spacing={4} justifyContent="center">
        {/* Brand and Social Media */}
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              Entick
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              The ultimate marketplace for buying and selling nightclub tickets.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                justifyContent: { xs: 'center', md: 'flex-start' },
              }}
            >
              <IconButton href="/" color="inherit">
                <FacebookIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />
              </IconButton>
              <IconButton href="https://www.instagram.com/entickltd/" color="inherit">
                <InstagramIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />
              </IconButton>
              <IconButton href="https://twitter.com/EntickLtd" color="inherit">
                <TwitterIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />
              </IconButton>
              <IconButton href="https://tiktok.com/entick_marketplace" color="inherit">
                <MusicNoteIcon fontSize="medium" sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Box>
          </Box>
        </Grid>

        {/* Quick Links */}
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Quick Links
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Link href="/" underline="hover" color="inherit">
                Home
              </Link>
              <Link
                underline="hover"
                color="inherit"
                onClick={() => navigateTo('/login')}
                sx={{ cursor: 'pointer' }}
              >
                Sign Up
              </Link>
              <Link
                underline="hover"
                color="inherit"
                onClick={() => navigateTo('/discover')}
                sx={{ cursor: 'pointer' }}
              >
                Events
              </Link>
              <Link
                underline="hover"
                color="inherit"
                onClick={() => navigateTo('/sell')}
                sx={{ cursor: 'pointer' }}
              >
                Start Selling
              </Link>
            </Box>
          </Box>
        </Grid>

        {/* Newsletter Subscription */}
        <Grid item xs={12} md={4}>
          <Box sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
              Sign Up to Our App Launch
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              We are soon launching a mobile app! Sign Up here to have first dibs.
            </Typography>
            <Box
              component="form"
              sx={{
                display: 'flex',
                gap: 1,
                justifyContent: { xs: 'center', md: 'flex-start' },
                flexWrap: 'wrap',
              }}
              onSubmit={emilSubmitHandler}
            >
              <input
                type="email"
                placeholder="Enter your email"
                name='email'
                style={{
                  padding: '10px',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  outline: 'none',
                  width: '200px',
                }}
              />
              <button
                type="submit"
                style={{
                  padding: '10px 20px',
                  backgroundColor: theme.palette.primary.main,
                  color: '#fff',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Subscribe
              </button>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Footer Bottom Section */}
      <Box sx={{ textAlign: 'center', mt: 6 }}>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Bringing the nightlife to your fingertips.
        </Typography>
        <Typography variant="body2" color="textSecondary">
          &copy; {new Date().getFullYear()} Entick. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
}
